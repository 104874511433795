import React, { useEffect, useState } from "react";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    DateTimePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0),
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main + '!important',
                borderWidth: '1px !important'
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.light + '!important',
                // borderWidth: '1px !important'
            },
            '& .MuiOutlinedInput-input': {
                padding: '15px'
            }
        },
        '& label': {
            color: theme.palette.primary.main
        }
    },
}))

function StopClockingsDateTimeField({ label, name, defaultValue, disabled, onChange, format, minDate, maxDate }) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null);
    // console.log('selected=====', defaultValue, selectedDate)

    useEffect(() => {
        if (defaultValue !== 'Invalid date') {
            setSelectedDate(defaultValue ? moment(defaultValue, "YYYY-MM-DD HH:mm:ss") : null)
        }
        else {
            setSelectedDate("")
            // setErrors({
            //     ...errors,
            //     [name]: { valid: false }
            // })
        }
    }, [defaultValue]);

    const changeDate = (date) => {
        if (date === null) {
            // setErrors({
            //     ...errors,
            //     [name]: { valid: false }
            // })
            setSelectedDate("")
            onChange && onChange("")
        } else {
            setSelectedDate(date);
            onChange && onChange(moment(date))
            // setErrors({
            //     ...errors,
            //     [name]: { valid: true }
            // })
        }
    }


    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                clearable
                className={classes.root}
                fullWidth
                autoOk
                disabled={disabled}
                // focused
                id=""
                ampm={false}
                inputVariant="outlined"
                label={label}
                maxDate={maxDate}
                // variant={variant}
                minDate={minDate}
                color={"primary"}
                format={format ?? "DD/MM/YYYY  HH:mm"}
                value={selectedDate}
                // // onBlur={event => console.log(event.target.value)}
                // InputAdornmentProps={{ position: "end" }}
                // error={errors && errors[name] && !errors[name]?.valid}
                onChange={date => { changeDate(date) }}
            />
            <input style={{ display: 'none' }} name={name || "date"} defaultValue={defaultValue} value={selectedDate ? moment(selectedDate).format("DD/MM/YYYY  HH:mm") : null} />
        </MuiPickersUtilsProvider>
    )
}

export default React.memo(StopClockingsDateTimeField);