import React from 'react'
import { Dialog, DialogContent, makeStyles, createStyles, Typography, Grid, Paper, Box, CircularProgress, DialogTitle } from '@material-ui/core'
import { useState, useContext } from 'react';
import MROButton from 'components/buttons';
import { LineMaintainenceServices } from 'lib/services/api/dashboard/line_maintenance';
import { ToastMessageContext } from 'lib/contexts/message_context';
const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "700px",
                // height: "350px"
            },
        },

    })
);

function CancelEvent(props) {


    const classes = useStyles()
    const { handleClose, currentSelection } = props
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)

    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }

    const handleSubmit = () => {

        const formData = new FormData()
        formData.append("event_id", currentSelection.id)
        setLoading(true)
        LineMaintainenceServices.updateCancelEvent(formData).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Event cancelled successfully !",
                    variant: "success",
                });
                handleClose()
            }
        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: res.errors ? res.errors : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }


    return (
        <div>   <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="allocate engineers"
            aria-describedby="allocate engineers"
            className={classes.modal}
            container={container()}
        >
            <DialogTitle>
                <Typography color='primary' variant='subtitle1' style={{ fontSize: '18px', textAlign: 'center' }}>Cancel Event</Typography>
            </DialogTitle>
            <DialogContent>

                <Typography style={{ textAlign: 'center' }}>Cancelling an event removes it from your dashboard incase the event has been cancelled by the operator or is not going to arrive anymore. Do you want to continue?</Typography>


                <Grid item xs={12} container direction='row' style={{ justifyContent: 'center', marginTop: '40px', marginBottom: '30px' }} >

                    <MROButton type='button' variant='contained' style={{ marginRight: '23px' }} onClick={() => {
                        handleClose()
                    }}>

                        Cancel
                    </MROButton>
                    <MROButton variant='contained' color='primary' loading={loading}
                        onClick={handleSubmit}
                    >

                        Yes
                    </MROButton>


                </Grid>


            </DialogContent>
        </Dialog>
        </div>
    )
}

export default CancelEvent