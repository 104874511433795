import React, { useState, useEffect, useContext } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { Box, CircularProgress, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import MROButton from "components/buttons";
import EditStageOrder from "./EditStageOrder";
import EditActionInfo from "./EditActionInfo";
import FileUpload from "components/form_components/fileUpload";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { ToastMessageContext } from "lib/contexts/message_context";
import _ from "lodash";
import { formatDateFunction } from "lib/utils/common_utils";
import RescheduleIcon from "@material-ui/icons/History";
import HistoryTablePopup from "./HistoryTablePopup";
import ManHousrHistory from "./ManhoursHistory";
import ReorderIcon from '@material-ui/icons/Reorder';
const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  root: {
    backgroundColor: "#ffffff",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  EditIcon: {
    bgcolor: "primary",
    color: "#fff",
  },
  reorderIcon: {
    color: theme.palette.secondary.main
  },
  editButton: {
    marginLeft: "300px",
  },
  description: {
    borderRadius: "5px",
    height: "max-content",
    fontSize: "15px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(1),
    whiteSpace: 'break-spaces'
  },
  partKey1: {
    fontSize: "14px",
    // padding: "5px",
  },
  partValue1: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "5px",
    display: "flex",
    alignItems: "center",
  },
  partCard1: {
    borderRadius: "8px",
    padding: "12px 16px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
}));

function ActionInformation(props) {
  const classes = useStyles();
  const {
    handleClose,
    selectedId,
    getAllActions,
    setSelectedRowId,
    styleProp,
    readProp,
    orderState,
    planning_verification
  } = props;
  const [action, setAction] = useState(null);
  const message = useContext(ToastMessageContext);
  const [loading, setLoading] = useState(false);
  const [stageDetails, setStageDetails] = useState([]);
  const [selectedAction, setSelectedAction] = useState([]);
  const [currentSelection, setCurrentSelection] = useState({})

  // this for history table popup
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMan, setAnchorElMan] = useState(null);
  const [busy, setBusy] = useState(null);
  const [popupData, setPopupData] = useState([]);
  // for opening history table popup
  const handleClickHistory = (event) => {
    setAnchorEl(true);
  };
  const handleClick = (event) => {
    setAnchorElMan(true);
  };

  // for closing history table popup
  const handleCloseFunc = () => {
    setAnchorEl(false);
  };
  const handleCloseFuncMan = () => {
    setAnchorElMan(false);
  };

  const handleEdit = () => {
    setAction("EditInfo");
  };

  const handleCloseEdit = () => {
    if (action === "EditInfo" || action === "EditActionInfo") {
      setAction(null);
    }
  };

  // console.log("order state is================", orderState)

  useEffect(() => {
    getAllActionStages();
  }, []);

  const getAllActionStages = async () => {
    const params = {
      workshop_action_id: selectedId,
    };
    await InspectionServices.getAllActionStages(params)
      .then((res) => {
        if (res) {
          setStageDetails(res.data);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        // setIsGettingDetails(false);
      });
  };

  useEffect(() => {
    getSelectedAction();
  }, []);

  const getSelectedAction = async () => {
    setLoading(true);
    await InspectionServices.getSelectedAction(selectedId)
      .then((res) => {
        if (res) {
          setLoading(false);
          setSelectedAction(res.data);
        }
      })
      .catch(() => {
        setLoading(false);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseButton = () => {
    props.getAllActionStages();
    handleClose();
  };
  const GetInfoCard = (props) => {
    return (
      <Box display="flex" pl={'5px'}>
        <Box flex={35} className={classes.partKey1}>
          <Typography style={{ padding: "5px" }} color="primary">
            {props?.keyName}
          </Typography>
        </Box>
        <Box flex={65} className={classes.partValue1}>
          {props?.value ?? "-"}
        </Box>
      </Box>
    );
  };




  return (
    <MROFullScreenRightDrawer open={true}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            onClick={handleCloseButton}
            edge="start"
            className={handleClose}
            color="inherit"
            aria-label="back"
          >
            <BackIcon />
          </IconButton>
          <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
            Action Information
          </Typography>
          {planning_verification === "Not Verified" && props?.permission.write &&
            <div style={{ display: styleProp }}>
              <IconButton style={{ marginLeft: "920px" }}>
                <EditIcon
                  fontSize="small"
                  htmlColor={ICON_COLOUR}
                  onClick={() => setAction("EditActionInfo")}
                />
              </IconButton>
            </div>}
        </Toolbar>
      </AppBar>

      <div className={classes.root}>
        <div className={classes.wrapper}>
          {loading && _.isEmpty(selectedAction) ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="700px"
            >
              <CircularProgress color="primary" size={40} />
            </Box>
          ) : (
            <>
              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Action Details
                </Typography>
              </Grid>


              <div style={{ width: "100%", marginBottom: "20px" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  // justifyContent={(loading || _.isEmpty(currentSelection)) && "center"}
                  bgcolor="#F5F5F5"
                  className={classes.partCard1}
                >
                  <>
                    <Box flexGrow={1}>
                      <div style={{ width: "100%" }}>
                        <GetInfoCard keyName={"Raised by"} value={selectedAction?.raised_by} />
                        <GetInfoCard keyName={"Type"} value={selectedAction?.action_category} />
                        <GetInfoCard keyName={"Mech Auth"} value={selectedAction?.mech_auth} />
                        <GetInfoCard keyName={"Insp Auth"} value={selectedAction?.insp_auth} />
                        <GetInfoCard keyName={"Title"} value={selectedAction?.title} />

                      </div>
                    </Box>

                    <Box flexGrow={1}>
                      <div style={{ width: "100%" }}>
                        <GetInfoCard keyName={"Raised"} value={selectedAction?.raised_date_time ? formatDateFunction(selectedAction?.raised_date_time) : ""} />
                        <GetInfoCard keyName={"ATA Chapter"} value={selectedAction?.ata_chapter} />
                        <GetInfoCard keyName={"Estimated Man-Hrs"} value={selectedAction?.estimate_man_hours} />
                        <GetInfoCard keyName={"Safety Critical"} value={selectedAction?.critical_considerations === false
                          ? "No"
                          : "Yes"} />
                        <GetInfoCard keyName={"Ind Insp"} value={selectedAction?.independent_insp_required === false
                          ? "No"
                          : "Yes"} />
                      </div>
                    </Box>

                  </>
                </Box>
              </div>


              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Detailed Description
                </Typography>
              </Grid>
              <Grid className={classes.description}>
                <Typography style={{ fontSize: "15px" }}>
                  {selectedAction?.detailed_description}
                </Typography>
              </Grid>

              <Typography
                color="primary"
                varient="h3"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Tech Data
              </Typography>
              <Grid>
                <FileUpload
                  defaultValue={
                    selectedAction?.maintenance !== undefined
                      ? selectedAction?.maintenance.map((item) => ({
                        filename: item?.file_name,
                        url: `${item?.path}`,
                        id: item?.id,
                      }))
                      : ""
                  }
                  showBorderOnNoFiles={true}
                  APIUrl={""}
                  multiple={true}
                  name={"maintenance_file[]"}
                  action={"Upload Document"}
                  uploadButtonView={true}
                />
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Verification
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Verified</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {selectedAction?.verified_date_time === null
                        ? "-"
                        : formatDateFunction(
                          selectedAction?.verified_date_time
                        )}
                    </Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Verified by</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                      {selectedAction?.verified_by === null
                        ? "-"
                        : selectedAction?.verified_by}
                    </Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Stages
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Number of Stages</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{stageDetails.length || "-"}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Reorder Stages</Typography>
                  </Box>
                  {planning_verification === "Verified" &&
                    <Box flex={38} className={classes.partKey}>
                      <Typography>{"-"}</Typography>
                    </Box>}
                  {planning_verification === "Not Verified" && props?.permission.write &&
                    <Box flex={38} className={classes.partKey}>
                      <Typography>
                        {(
                          <>
                            <ReorderIcon className={classes.reorderIcon} onClick={handleEdit}
                              style={{ display: readProp ? "none" : "-", cursor: 'pointer' }} />
                          </>



                        ) || "-"}
                      </Typography>
                    </Box>
                  }
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Man-Hrs
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Actual Man-hrs</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.actual_man_hours || "-"
                    }</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography></Typography>
                  </Box>
                  {/* <Box flex={54} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box> */}
                  <Box display={"flex"} justifyContent="flex-end" className={classes.partKey}>
                    <Box display="flex">
                      <Tooltip title="History">
                        <IconButton
                          onClick={(e) => {
                            handleClick(e);
                            setCurrentSelection(currentSelection);
                          }}
                          className={"text-info"}
                        >
                          <RescheduleIcon />
                        </IconButton>
                      </Tooltip>
                      <ManHousrHistory
                        busy={busy}
                        open={anchorElMan}
                        handleClick={handleClick}
                        handleClose={handleCloseFuncMan}
                        currentSelection={currentSelection}
                        selectedId={selectedId}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Typography
                  color="primary"
                  varient="h3"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  Closure
                </Typography>
              </Grid>
              <Grid className={classes.paper}>
                <Box display="flex">
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Closed</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.closed_date_time === null
                      ? "-"
                      : formatDateFunction(
                        selectedAction?.closed_date_time
                      )}</Typography>
                  </Box>
                  <Box flex={18} className={classes.partKey}>
                    <Typography color="primary">Closed by</Typography>
                  </Box>
                  <Box flex={38} className={classes.partKey}>
                    <Typography>{selectedAction?.closed_by || "-"}</Typography>
                  </Box>
                  <Box flex={54} className={classes.partKey}>
                    <Typography color="primary"></Typography>
                  </Box>
                  <Box display={"flex"} justifyContent="flex-end" className={classes.partKey}>
                    <Box display="flex">
                      <Tooltip title="History">
                        <IconButton
                          onClick={(e) => {
                            handleClickHistory(e);
                            setCurrentSelection(currentSelection);
                          }}
                          className={"text-info"}
                        >
                          <RescheduleIcon />
                        </IconButton>
                      </Tooltip>
                      <HistoryTablePopup
                        busy={busy}
                        open={anchorEl}
                        handleClick={handleClickHistory}
                        handleClose={handleCloseFunc}
                        currentSelection={currentSelection}
                        data={popupData}
                        selectedId={selectedId}
                        type={false}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </div>
      </div>

      {action === "EditInfo" && (
        <EditStageOrder
          handleClose={handleCloseEdit}
          closeDrawer={handleClose}
          stageDetails={stageDetails}
          workshop_id={selectedId}
          getAllActionStages={props.getAllActionStages}
        />
      )}
      {action === "EditActionInfo" && (
        <EditActionInfo
          selectedId={selectedId}
          closeHandler={handleClose}
          handleClose={handleCloseEdit}
          selectedAction={selectedAction}
          getAllActions={getAllActions}
          getSelectedAction={getSelectedAction}
          setSelectedRowId={setSelectedRowId}
        />
      )}
    </MROFullScreenRightDrawer>
  );
}

export default ActionInformation;
