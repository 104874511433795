import React, { useReducer, useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import tableReducer from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import { ToastMessageContext } from "lib/contexts/message_context";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { formatDateWithHrMin } from "lib/utils/common_utils";
import { IconButton } from "@material-ui/core";
import RedCrossIcon from 'assets/Red remove icon.png'
import GreenTickIcon from 'assets/green check.png'
import CustomerSupply from "./CustomerSupply";

function LineItems(props) {
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, pageSize } = tableState;
    const { selectedData, selectedActionRowData, setLoading, loading, getActionBreakdown } = props
    const [data, setData] = useState({})
    const [totalCount, setTotalCount] = useState(10)
    const [action, setAction] = useState('')
    const [selectedParts, setSelectedparts] = useState({})

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        rowStyle: { height: 42 },
    };
    const getListItems = async () => {
        setLoading(true)

        const params = {
            workshop_action_id: selectedData.id,
            item: selectedActionRowData.item.toLowerCase(),
            page: 1 + page,
            limit: pageSize,
        };
        await CommercialServices.getListItems(params)
            .then((res) => {

                console.log("ListData Response", res)
                setData(res)
                setTotalCount(res.total_count)
            })
            .catch(() => {
                console.log("error");
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        getListItems();

    }, [page, pageSize, selectedActionRowData, totalCount]);
    const colors = { amber: "#FFB300", green: "#4FC605", red: "#FF0202" }

    const LabourColumn = [{
        title: "Date",
        field: "date",
        headerStyle: {
            textAlign: "left",
            width: "20%",
            paddingLeft: "22px"
        },
        cellStyle: {
            textAlign: "left",
            width: "20%",
            paddingLeft: "22px"
        },
        render: (rowData) => rowData.date ? formatDateWithHrMin(rowData.date) : '-'
    },
    {
        title: "User",
        field: "user",
        headerStyle: {
            textAlign: "left",
            width: "60%",
        },
        cellStyle: {
            textAlign: "left",
            width: "60%",
        },
    },
    {
        title: "Hours",
        field: "hours",
        headerStyle: {
            textAlign: "center",

        },
        cellStyle: {
            textAlign: "center",
        }
    },
    {
        title: "Charge",
        field: "charges",
        headerStyle: {
            textAlign: "center",

        },
        cellStyle: {
            textAlign: "center",

        },
    },
    ]


    const column = [
        {
            title: "ID",
            field: "id",
            headerStyle: {
                textAlign: "left",
                width: "10%",
                paddingLeft: "22px"
            },
            cellStyle: {
                textAlign: "left",
                width: "10%",
                paddingLeft: "22px"
            },
            render: (rowData) => {
                return (
                    <span>{rowData.id === null ? "-" : rowData.id}</span>
                )
            },
        },

        {
            title: "Item",
            field: "item",
            headerStyle: {
                textAlign: "left",
                width: "10%",
            },
            cellStyle: {
                textAlign: "left",
                width: "10%",
            },
        },
        {
            title: "Description",
            field: "description",
            headerStyle: {
                textAlign: "left",
                width: "50%"
            },
            cellStyle: {
                textAlign: "left",
                width: "50%",
            },
        },
        {

            title: "Customer Supply",
            field: 'customer_supply',
            hidden: selectedActionRowData.item === 'Parts' ? false : true,
            render: (rowData) => (
                <IconButton onClick={() => {
                    setAction('customersupply')
                    setSelectedparts(rowData)
                }}>
                    <img src={rowData.customer_supply === true ? GreenTickIcon : RedCrossIcon} height={'18px'} />
                </IconButton>
            )
        },
        {

            title: "Price Ages(days)",
            field: 'Ages',
            hidden: selectedActionRowData.item === 'Parts' ? false : true,
            render: (rowData) => (
                <div style={{ color: rowData.price_age >= 0 && rowData.price_age <= 7 ? colors.green : rowData.price_age > 7 && rowData.price_age < 30 ? colors.amber : colors.red, fontWeight: 600 }}>
                    {rowData.price_age !== null || rowData?.price_age !== undefined || rowData.price_age !== "" ? rowData.price_age : '-'}
                </div>

            )

        },
        {
            title: "QTY",
            field: "quantity",
        },
        {
            title: "Charge",
            field: "charge",
        },
    ];

    return (
        <>
            <MaterialTable
                style={{
                    boxShadow: "0px 1px 4px #00000033",
                }}
                icons={tableIcons}
                title={""}
                columns={selectedActionRowData.item === 'Labour' ? LabourColumn : column}
                data={data?.data || []}
                options={tableOptions}
                isLoading={loading}
                components={{
                    Toolbar: () => null,
                }}
                localization={{
                    toolbar: {},
                    body: {
                        emptyDataSourceMessage: "No Line Items found",
                        filterRow: {
                            filterTooltip: "Filter",
                        },
                    },
                }}
                onChangePage={(page) => {
                    TableAction.setPage(dispatch, page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                    TableAction.setPageSize(dispatch, pageSize);
                }}
                totalCount={totalCount}
                page={page}
            />
            {action && action === 'customersupply' && <CustomerSupply handleClose={() => setAction(null)} getListItems={getListItems} selectedParts={selectedParts} getActionBreakdown={getActionBreakdown} />}
        </>
    );
}

export default LineItems;




