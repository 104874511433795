import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography, makeStyles } from '@material-ui/core'
import MROButton from 'components/buttons'
import { CommercialServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/commercial'
import { ToastMessageContext } from 'lib/contexts/message_context'



const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paperWidthMd": {
            boxShadow: "0px 3px 6px #00000029",
            // position: "absolute",
            // left: "calc(50% - 250px)",
            width: '28%',
            height: '188px',
            borderRadius: '5px'
        }
    },
    dialogTitle: {
        color: theme.palette.primary.main,
        fontSize: '18px',
        textAlign: 'center'
    }

}))

function CustomerSupply(props) {
    const classes = useStyles()
    const { handleClose, selectedParts, getListItems, getActionBreakdown } = props
    // console.log("selectedParts===== ", selectedParts)
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)

    const handleComplete = async () => {
        setLoading(true)
        const params = {
            part_request_id: selectedParts?.id,
            customer_supply: selectedParts.customer_supply === false ? true : false
        }
        await CommercialServices.updateCustomerSupply(params).then((res) => {
            handleClose()
            getActionBreakdown()
            getListItems()

            message.showToastMessage({
                message: "Updated Successfully !",
                variant: "success",
            });
        }).catch(() => {
            handleClose()
            getActionBreakdown()
            getListItems()
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div> <Dialog
            // fullWidth={true}
            maxWidth={'md'}
            open={true}
            onClose={handleClose}
            className={classes.dialog}
            aria-labelledby="dispatch-files">

            <DialogTitle ><Typography variant='subtitle2' className={classes.dialogTitle}> Change Customer Supply </Typography> </DialogTitle>
            <DialogContent>

                <Typography style={{ textAlign: 'center' }}>Are you sure you want to the customer supply state? This will impact the commercials values for the job</Typography>
                <br />
                <div style={{ textAlign: 'center' }}>
                    <MROButton
                        //   disabled={loading}
                        type="button"
                        variant={"contained"}
                        onClick={handleClose}
                    >
                        Cancel
                    </MROButton>
                    <MROButton

                        style={{ marginLeft: '14px' }}
                        loading={loading}

                        variant={"contained"}
                        color="primary"
                        onClick={handleComplete}
                    >
                        Confirm
                    </MROButton>
                </div>
            </DialogContent>

        </Dialog>
        </div >
    )
}

export default CustomerSupply