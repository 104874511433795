import React, { useEffect, useState } from "react";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    DateTimePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import MROTextField from "./TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0),
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main + '!important',
                borderWidth: '1px !important'
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.light + '!important',
                // borderWidth: '1px !important'
            },
            '&.Mui-disabled fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.26) !important'
            },
            '&.Mui-error fieldset': {
                borderColor: '#f44336 !important',
            },
            '& .MuiOutlinedInput-input': {
                padding: '15px'
            }
        },
        '& label': {
            color: theme.palette.primary.main
        }
    },
}))



export const checkFromat = (date) =>
    /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(date);

function LMEventsDateTimeField(props) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null);
    const { label, name, defaultValue, variant, disabled, onlyTime, onChange, setErrors, errors, format, helperText, type
    } = props;

    function container() {
        // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
        return document.fullscreenElement ?? document.body;
    }

    useEffect(() => {
        if (defaultValue !== 'Invalid date') {
            setSelectedDate(defaultValue ? moment(defaultValue, onlyTime ? "HH:mm" : "DD/MM/YYYY HH:mm:ss a") : null)
        }
        else {
            setSelectedDate("")
        }
    }, [defaultValue]);

    const changeDate = (date) => {
        if (date === null) {

            setSelectedDate("")
            onChange && onChange("")
        } else {
            setSelectedDate(date);
            onChange && onChange(moment(date).format("DD/MM/YYYY HH:mm:ss"))

        }
    }

    const changeTime = (date) => {
        setSelectedDate(date); onChange && onChange(moment(date).format("DD/MM/YYYY HH:mm:ss"))
    }
    moment.locale("es-es", {
        week: {
            dow: 1 //Monday is the first day of the week.
        }
    });
    if (onlyTime) {
        return <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
                DialogProps={{ container: container }}
                PopoverProps={{ container: container }}
                clearable
                className={classes.root}
                fullWidth
                autoOk
                disabled={disabled}
                // focused
                id=""
                inputVariant="outlined"
                label={label}
                variant={variant}
                color={"primary"}
                ampm={false}
                value={selectedDate}
                InputAdornmentProps={{ position: "end" }}
                error={errors && errors[name]}
                onChange={date => { changeTime(date) }}
            />
            <input style={{ display: 'none' }} name={name || "date"} defaultValue={defaultValue} value={selectedDate ? moment(selectedDate).format("HH:mm") : null} />
        </MuiPickersUtilsProvider>
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                DialogProps={{ container: container }}
                PopoverProps={{ container: container }}
                clearable
                className={classes.root}
                fullWidth
                autoOk
                disabled={disabled}
                // focused
                id=""
                ampm={false}
                inputVariant="outlined"
                label={label}
                // variant={variant}
                color={"primary"}
                format={format ?? "DD/MM/YYYY HH:mm"}
                value={selectedDate}
                // // onBlur={event => console.log(event.target.value)}
                // InputAdornmentProps={{ position: "end" }}
                error={errors && errors}
                onChange={date => { changeDate(date) }}
                InputLabelProps={{ shrink: true }}

            />
            <input style={{ display: 'none' }} name={name || "date"} defaultValue={defaultValue} value={selectedDate ? moment(selectedDate).format(type === "planned" ? "DD/MM/YYYY HH:mm:ss" : "YYYY-MM-DD HH:mm:ss") : null} />

            {errors && (
                <p style={{ color: "red", fontSize: 12 }}>
                    {helperText}
                </p>
            )}
        </MuiPickersUtilsProvider>
    )
}

export default LMEventsDateTimeField;














