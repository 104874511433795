import React, { useCallback, useEffect, useState } from 'react';
import MROTheme, { organisationDetails } from "./theme/main_theme";
import { BrowserRouter as Router } from 'react-router-dom';
import BrowserAppRoutes from "./views/Routes";
import { ThemeProvider } from '@material-ui/styles';
import ToastMessageProvider from "./lib/contexts/message_context";
import { CustomPreloader } from "react-preloaders";
import './App.css';
import WorkshopContextProvider from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import PlannedWorkOrderProvider from 'views/Planning/Line maintenance/Planned WO/PlannedWorkOrderContext';
import AuthActions from 'actions/auth_actions';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';


function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // alert("Auth" + queryString)
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userRole = user ? user.role : null;
    setUser(userRole)
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Configuration for timeouts
  // const sessionTimeout = 2880 * 60 * 1000; // 48 hourse
  // let logoutTimer;
  // // Reset activity timers
  // const resetTimers = useCallback(() => {
  //   clearTimeout(logoutTimer);
  //   // Set a new logout timer
  //   logoutTimer = setTimeout(() => {
  //     handleLogout(); // Auto-logout the user
  //   }, sessionTimeout);
  // }, [sessionTimeout]);

  // // Handle logout
  // const handleLogout = () => {
  //   let path = window.location.pathname;
  //   const routesToCheck = ["/", "/auth/signin", "/auth/two-factor-auth", "/auth/", "/auth/reset-password", "/auth/set-password", "/auth/set-pin", "/auth/forgot-password"];
  //   // Check if none of the routes in routesToCheck match the current path
  //   if (!routesToCheck.some((route) => route === path)) {
  //     AuthActions.userSignOut();
  //     alert("Session Expired. Sign In Again.");
  //   }
  // };
  // useEffect(() => {
  //   // Start the initial timers
  //   resetTimers();
  //   // Cleanup event listeners and timers on component unmount
  //   return () => {
  //     clearTimeout(logoutTimer);
  //   };
  // }, [resetTimers]);

  // Date time selection component set start of the week as Monday globally
  moment.locale("en-gb", {
    week: {
      dow: 1
    }
  });


  return (
    <Router onUpdate={() => document.getElementById('main_content').scrollTo(10, 10)}>
      {
        loading && <CustomPreloader background={organisationDetails && organisationDetails.primary_color_code ? organisationDetails.primary_color_code : "#145999"} color={"#fff"}>
          <img width={"200px"} src={"/loading_icon.gif"} alt={"loading..."} />
        </CustomPreloader>}

      <ThemeProvider theme={MROTheme}>
        <ConfigProvider locale={en_GB}>
          <ToastMessageProvider>
            <WorkshopContextProvider>
              <PlannedWorkOrderProvider>
                <BrowserAppRoutes />
              </PlannedWorkOrderProvider>
            </WorkshopContextProvider>
          </ToastMessageProvider>
        </ConfigProvider>
      </ThemeProvider>

    </Router>
  );
}

export default App;
