import React, { useState, useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, Divider, FormControl, Menu, Button, FormControlLabel, Paper } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Training from './Training';
import Competency from './Competency';
import Regency from './Regency';
import ReadAndSigns from './Read&Signs';
import Approvals from './Approvals';
import Chart from './Chart';
import SearchBarWithFilters from "components/search_with_filters";
import PositiveSwitch from "components/form_components/switch";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from "lib/contexts/message_context";
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import _without from "lodash/without";
import { UserDashboardServices } from 'lib/services/api/dashboard/user_compiance';
import ReactApexChart from 'react-apexcharts'
import ChartLoader from '../Workshops/Storage/ChartLoader';
import { useEffect } from 'react';








export default function UserCompliance(props) {

  const classes = useStyles()
  const [bases, setBases] = useState([]);

  const message = useContext(ToastMessageContext);
  const [anchorBase, setAnchorBase] = useState(null);
  const [baseButton, setBaseButton] = useState(false)
  const [selectedBase, setSelectedBase] = useState([]);
  const [checkedState, setCheckedState] = useState(new Array(bases.length).fill(false));
  const [loading, setLoading] = useState(false)
  const [approvalData, setApprovalData] = useState({})
  const [trainingData, setTrainingData] = useState({})
  const [competencyData, setCompetencyData] = useState({})
  const [trainingPercentage, setTrainingPercentage] = useState(0);
  const [competencyPercentage, setCompetencyPercentage] = useState(0);
  const [approvalPercentage, setApprovalPercentage] = useState(0);
  const [action, setAction] = useState('')
  const [readSignData, setReadSignData] = useState({})
  const [readSignPercentage, setReadSignPercentage] = useState(0);
  //Recency Data

  const [recencyExpiredData, setRecencyExpiredData] = useState({})
  const [recencyLowData, setRecencyLowData] = useState({})
  const [recencyGoodData, setRecencyGoodData] = useState({})
  const [recencyLoading, setRecencyLoading] = useState(false)
  const [recencyPercentage, setRecencyPercentage] = useState(0)

  const ITEM_HEIGHT = 78;
  const getBaseStation = async () => {
    const params = {
      organisation_id: getOrganisationId(),
      order_by: 'station'
    }
    MaintenanceServices.getBaseStation(params)
      .then((res) => {
        if (res) {
          setBases(res.data);
        }

      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });

      });
  };



  useEffect(() => {
    getBaseStation()
  }, [])


  //get user compliance



  const getUserComplianceData = async () => {
    setLoading(true)
    const params = {
      organisation_id: getOrganisationId(),

    }
    selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
    await UserDashboardServices.getUserCompliance(params).then((response) => {
      setApprovalData(response.data.approvals[0])
      setTrainingData(response.data.training[0])
      setCompetencyData(response.data.competency[0])
      setReadSignData(response.data.read_sign[0])

    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      });
    }).finally(() => {
      setLoading(false)
    })

  }
  useEffect(() => {
    getUserComplianceData()
  }, [selectedBase])



  //Recency donut chart  api calling

  const getRecencyData = async () => {
    setRecencyLoading(true)
    const params = {
      organisation_id: getOrganisationId(),
      base_station_id: selectedBase.join(',')
    }
    await UserDashboardServices.getRecencyChartApi(params).then((response) => {

      if (response.success) {
        response.data.filter((item) => {
          if (item.overall_status_name === "Amber") {
            setRecencyLowData(item)
          }
          if (item.overall_status_name === "Green") {
            setRecencyGoodData(item)
          }
          if (item.overall_status_name === "Red") {
            setRecencyExpiredData(item)
          }
        })

      }

    }).catch(() => {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      });
    }).finally(() => {
      setRecencyLoading(false)
    })

  }

  useEffect(() => {
    getRecencyData()
  }, [selectedBase])





  const handleBaseClick = (event) => {
    setAnchorBase(event.currentTarget);
    setBaseButton(!baseButton)
  };
  const filterChangeHandler = (e) => {
    const { name, value, checked } = e.target
    console.log("filter====", name, value, checked)
    setSelectedBase((prevState) => [...prevState, value])
    if (!checked) {
      setSelectedBase((current) => _without(current, value));
    }

  }



  // donut percentage calculation
  useMemo(() => {
    let totalValue = trainingData.on_track + trainingData.near_due + trainingData.overdue
    let percentage = (trainingData.on_track / totalValue) * 100

    if (totalValue === 0) {
      setTrainingPercentage(totalValue)
    }
    else {
      setTrainingPercentage(Math.floor(percentage))
    }
  }, [trainingData])

  useMemo(() => {
    let totalValue = competencyData.on_track + competencyData.near_due + competencyData.overdue
    let percentage = (competencyData.on_track / totalValue) * 100

    if (totalValue === 0) {
      setCompetencyPercentage(totalValue)
    }
    else {
      setCompetencyPercentage(Math.floor(percentage))
    }
  }, [competencyData])



  useMemo(() => {
    let totalValue = approvalData.on_track + approvalData.near_due + approvalData.overdue
    let percentage = (approvalData.on_track / totalValue) * 100

    if (totalValue === 0) {
      setApprovalPercentage(totalValue)

    }
    else {
      setApprovalPercentage(Math.floor(percentage))
    }
  }, [approvalData])


  useMemo(() => {
    let totalValue = readSignData.on_track + readSignData.near_due + readSignData.overdue
    let percentage = (readSignData.on_track / totalValue) * 100

    if (totalValue === 0) {
      setReadSignPercentage(totalValue)

    }
    else {
      setReadSignPercentage(Math.floor(percentage))
    }
  }, [readSignData])

  useMemo(() => {


    let totalValue = recencyGoodData.overall_status + recencyLowData.overall_status + recencyExpiredData.overall_status
    let percentage = (recencyGoodData.overall_status / totalValue) * 100

    if (totalValue === 0) {
      setRecencyPercentage(totalValue)

    }
    else {
      setRecencyPercentage(Math.floor(percentage))
    }

  }, [recencyExpiredData, recencyGoodData, recencyLowData])





  return (
    <div style={{ paddingRight: '15px', paddingLeft: '14px' }}>
      <div className={classes.root}>
        <SearchBarWithFilters noSearch padding={'4px 16px'}>




          <FormControl style={{ marginLeft: '-11px' }}>
            <Button variant={anchorBase ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
              {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}

            </Button>
            <Menu
              id="Base"
              anchorEl={anchorBase}
              keepMounted
              open={Boolean(anchorBase)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              onClose={() => {
                setAnchorBase(null)
                setBaseButton(!baseButton)
              }}
              // anchorOrigin={{
              //     vertical: 'bottom',
              //     horizontal: 'left',
              // }}
              style={{ marginTop: '0px' }}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '39ch',
                  position: 'absolute',
                  top: '260px',
                },
              }}
            >
              {
                bases?.map((base, index) => {
                  return <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={8} style={{
                        display: "flex", alignItems: "center", fontSize: "14px",

                      }}>
                        <label>{base.station}</label>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          style={{ alignSelf: "flex-end" }}
                          control={<PositiveSwitch
                            checked={checkedState[index]}
                            value={base.id}
                            // onChange={(e) => handleChange(e, option.id)}
                            onChange={(e) => { filterChangeHandler(e, index) }}
                            name={base.station} />}
                        // name="base"
                        />
                      </Grid>
                    </Grid>
                    <Grid><Divider variant="middle" /></Grid>
                  </Box>
                }

                )}
            </Menu>
          </FormControl>

        </SearchBarWithFilters>
      </div>
      <Box width="100%" style={{ paddingLeft: '18px', marginTop: '-31px' }} >
        <Grid style={{ paddingLeft: '13px', paddingRight: '31px' }}>
          <Typography color='primary' style={{ fontSize: '15px', fontWeight: 600 }}>Users</Typography>
          <Divider />
        </Grid>
        <Box mt={3}>

          <Grid container xs={12} spacing={2} justifyContent='space-around'>
            <Grid xs={2} >
              <Paper style={{ width: '100%', height: '238px' }} onClick={() => setAction('training')}>
                {loading ? <ChartLoader /> : <>       <Grid >



                  <Chart
                    // values={quote}
                    value1={trainingData.on_track}
                    value2={trainingData.near_due}
                    value3={trainingData.overdue}
                    labels={["On Track", "Near Due", "Overdue"]}
                    // labelName={"Training"}
                    totalPercentage={trainingPercentage}
                  />
                  <div>
                    <Typography className={classes.quotePercentageText} >{trainingPercentage}{"%"}</Typography>
                    <Typography variant={"subtitle2"} color="primary" className={classes.centerLabel}>Training</Typography>
                  </div>
                </Grid>
                  <Grid container direction='row'>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#4FC605" }}>{trainingData.on_track}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >On Track</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FFB300" }}>{trainingData.near_due}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Near Due</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FF0202" }}>{trainingData.overdue}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Overdue</Typography></Grid>
                    </Grid>
                  </Grid>
                </>
                }
              </Paper>
            </Grid>
            <Grid xs={2}>
              <Paper style={{ width: '100%', height: '238px' }} onClick={() => setAction('competency')}>
                {loading ? <ChartLoader /> : <>            <Grid>


                  <Chart
                    // values={quote}
                    value1={competencyData.on_track}
                    value2={competencyData.near_due}
                    value3={competencyData.overdue}
                    labels={["On Track", "Near Due", "Overdue"]}
                    labelName={"Competency"}
                    totalPercentage={trainingPercentage}
                  />
                  <div>
                    <Typography className={classes.quotePercentageText} >{competencyPercentage}{"%"}</Typography>
                    <Typography variant={"subtitle2"} color="primary" className={classes.centerLabel}>Competency</Typography>
                  </div>
                </Grid>
                  <Grid container direction='row'>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#4FC605" }}>{competencyData.on_track}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >On Track</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FFB300" }}>{competencyData.near_due}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Near Due</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FF0202" }}>{competencyData.overdue}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Overdue</Typography></Grid>
                    </Grid>
                  </Grid>
                </>
                }
              </Paper>

            </Grid>
            <Grid xs={2}>

              <Paper style={{ width: '100%', height: '238px' }} onClick={() => setAction('approvals')}>
                {loading ? <ChartLoader /> : <>     <Grid>

                  <Chart
                    // values={quote}
                    value1={approvalData.on_track}
                    value2={approvalData.near_due}
                    value3={approvalData.overdue}
                    labels={["On Track", "Near Due", "Overdue"]}
                    labelName={"Approvals"}
                  // totalPercentage={quotesPercentage}
                  />
                  <div>
                    <Typography className={classes.quotePercentageText} >{approvalPercentage}{"%"}</Typography>
                    <Typography variant={"subtitle2"} color="primary" className={classes.centerLabel}>Approvals</Typography>
                  </div>
                </Grid>
                  <Grid container direction='row'>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#4FC605" }}>{approvalData.on_track}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >On Track</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FFB300" }}>{approvalData.near_due}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Near Due</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FF0202" }}>{approvalData.overdue}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Overdue</Typography></Grid>
                    </Grid>
                  </Grid>
                </>
                }
              </Paper>

            </Grid>
            <Grid xs={2}>

              <Paper style={{ width: '100%', height: '238px' }} onClick={() => setAction('recency')}>
                {recencyLoading ? <ChartLoader /> : <>     <Grid>

                  <Chart

                    value1={recencyGoodData.overall_status}
                    value2={recencyLowData.overall_status}
                    value3={recencyExpiredData.overall_status}
                    labels={["Good", "Low", "Expired"]}
                    totalPercentage={recencyPercentage}
                  />
                  <div>
                    <Typography className={classes.quotePercentageText} >{recencyPercentage}{"%"}</Typography>
                    <Typography variant={"subtitle2"} color="primary" className={classes.centerLabel}>Recency</Typography>
                  </div>


                </Grid>
                  <Grid container direction='row'>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#4FC605" }}>{recencyGoodData.overall_status}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Good</Typography></Grid>
                    </Grid>
                    {/* <Grid xs={3} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#707070" }}>0</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Overriden</Typography></Grid>
                    </Grid> */}
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FFB300" }}>{recencyLowData.overall_status}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Low</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FF0202" }}>{recencyExpiredData.overall_status}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Expired</Typography></Grid>
                    </Grid>
                  </Grid>
                </>
                }</Paper>

            </Grid>
            <Grid xs={2}>

              <Paper style={{ width: '100%', height: '238px' }} onClick={() => setAction('read&sign')}>
                {loading ? <ChartLoader /> : <>
                  <Grid>

                    <Chart
                      // values={quote}
                      value1={readSignData.on_track}
                      value2={readSignData.near_due}
                      value3={readSignData.overdue}
                      labels={["Read", "Pending", "Overdue"]}
                      // labelName={"Read & Signs"}
                      totalPercentage={trainingPercentage}
                    />
                    <div>
                      <Typography className={classes.quotePercentageText} >{readSignPercentage}{"%"}</Typography>
                      <Typography variant={"subtitle2"} color="primary" className={classes.readCenterLabel}>Read & Signs</Typography>
                      <Typography variant={"subtitle2"} color="primary" className={classes.oneyear}>(1-yr)</Typography>
                    </div>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#4FC605" }}>{readSignData.on_track}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Read</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FFB300" }}>{readSignData.near_due}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Pending</Typography></Grid>
                    </Grid>
                    <Grid xs={4} style={{ textAlign: "center" }}>
                      <Grid><Typography variant="h6" style={{ color: "#FF0202" }}>{readSignData.overdue}</Typography></Grid>
                      <Grid><Typography gutterBottom={20} variant="body2" style={{ fontSize: '12px' }} >Overdue</Typography></Grid>
                    </Grid>
                  </Grid>
                </>
                }
              </Paper>


            </Grid>
          </Grid>

        </Box>

        <Box mt={3} style={{ paddingRight: '16px' }}>
          <Grid style={{ paddingLeft: '13px', paddingRight: '13px' }}>
            <Typography color='primary' style={{ fontWeight: 600, fontSize: '15px' }}>Compliance Issues</Typography>
            <Divider />
            <br />
            <small>Select an indicator above to display the caution conditions</small>
          </Grid>
          <br />
          <Grid container spacing={-3} justifyContent="space-between">


            {action === 'training' && <Training selectedBase={selectedBase} />}
            {action === 'competency' && <Competency selectedBase={selectedBase} />}
            {action === 'approvals' && <Approvals selectedBase={selectedBase} />}
            {action === 'read&sign' && <ReadAndSigns selectedBase={selectedBase} />}
            {action === 'recency' && <Regency selectedBase={selectedBase} />}

          </Grid>
        </Box>

      </Box>
    </div>
  )
}


const useStyles = makeStyles(theme => ({
  alert: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.warning.main
  }, root: {
    width: "96%",
    margin: "4% 2%",
    paddingLeft: '4px',
    '&.MuiStepConnector-root': {
      borderTopWidth: '10px'
    }
  },
  centerLabel: {
    textAlign: ' center',
    marginTop: ' -50px',
    marginBottom: '66px',
    fontSize: '12px',

  },
  readCenterLabel: {
    textAlign: ' center',
    marginTop: ' -50px',
    fontSize: '12px',

  },
  oneyear: {
    fontSize: '11px',
    textAlign: 'center',
    /* margin-top: inherit; */
    marginBottom: '48px'
  },
  textSecondary: {
    color: theme.palette.secondary.main
  },
  quotePercentageText: {
    marginTop: ' -112px',
    marginBottom: ' 53px',
    textAlign: ' center',
    fontSize: '22px',
    color: " #4FC605"
  },
}))