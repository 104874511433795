// import request from "../../common/api_client";
import requestrApi from "../../common/boxr_api_client";

function getAllParts(params) {
  return requestrApi({
    url: "/primary_parts",
    method: "GET",
    params: params,
  });
}

function listingNonKits(params) {
  return requestrApi({
    url: "/primary_parts/listing_non_kits",
    method: "GET",
    params: params,
  });
}

/*function getAllParts() {
  return requestrApi({
    url: "/primary_parts?search_keyword&page=1&count_per_page=10",
    method: "GET",
  });
}*/

function getPrimaryPartInfo(id) {
  return requestrApi({
    url: `/primary_parts/primary_part_info?id=${id}`,
    method: "GET",
  });
}

function getAllPartsDependency(params) {
  return requestrApi({
    url: "/primary_parts/primary_parts_dependencies?search_keyword",
    method: "GET",
  });
}

/**
 * @param query search query
 * @param currentPrimaryPartId
 * */
function searchPrimaryPart(query, currentPrimaryPartId) {
  return requestrApi({
    // url: `https://dev-mropro-api.digiryte.co.uk/primary_parts/search_primary_parts?search_keyword=${query}&id=${currentPrimaryPartId}`,
    url: `/primary_parts?search_keyword=${query}&page=1`,
    method: "GET",
  });
}

/**
 * @param searchText string
 * @param baseStationId string | number
 * @param lineCustomerId string | number
 * @param mroOwned boolean
 */
function searchAllListedPrimaryParts(
  searchText,
  baseStationId,
  lineCustomerId,
  mroOwned
) {
  return requestrApi({
    url: `/primary_parts?search_keyword=${searchText}&page=1&count_per_page=10&base_station_id=${baseStationId}&line_customer_id=${lineCustomerId}&mro_owned=${mroOwned}`,
    method: "GET",
  });
}

function fetchDependencies() {
  return requestrApi({
    // url: '/parts/fetch_filter_dependencies',
    url: "/primary_parts/fetch_dependencies",
    method: "GET",
  });
}
function listOwnersODParts() {
  return requestrApi({
    url: "/parts/list_owners",
    method: "GET",
  });
}
function createParts(formData, isPrimaryPartFieldsDisabled) {
  const url = isPrimaryPartFieldsDisabled
    ? "/primary_parts/save_secondary_part"
    : "/primary_parts";

  return requestrApi({
    url: url,
    method: "POST",
    data: formData,
  });
}

function ddPartsFetchDependencies(params) {
  return requestrApi({
    // url: '/parts/fetch_filter_dependencies',
    url: "/dd_parts/fetch_dependencies",
    method: "GET",
    params
  });
}

function postDocument(formData) {
  return requestrApi({
    url: "/primary_parts/save_certificates",
    method: "POST",
    data: formData,
  });
}

function deleteFile(fileId) {
  return requestrApi({
    url: `/primary_parts/remove_certificate?id=${fileId}`,
    method: "DELETE",
  });
}

function fetchSampleDocument() {
  return requestrApi({
    url: "/parts/fetch_sample_document",
    method: "GET",
  });
}
function fetchRelatedParts(id) {
  return requestrApi({
    url: `/parts/fetch_related_parts?part_number=${id}`,
    method: "GET",
  });
}
function updateParts(formData) {
  return requestrApi({
    url: "/parts/update_part",
    method: "PUT",
    data: formData,
  });
} function updateSecPartsCertification(formData) {
  return requestrApi({
    url: "/purchase_orders/create_secondary_part_certification",
    method: "PUT",
    data: formData,
  });
}
function deleteParts(formData) {
  return requestrApi({
    url: `/parts/destroy_part`,
    method: "DELETE",
    data: formData,
  });
}
function bulkUpload(formData) {
  return requestrApi({
    url: "/parts/bulk_upload",
    method: "POST",
    data: formData,
  });
}
function changeStatus(formData) {
  return requestrApi({
    url: `/toolings/update_tooling`,
    method: "PUT",
    data: formData,
  });
}

function fetchStoreLocations(baseStationId, partNumber) {
  return requestrApi({
    url: `/primary_parts/fetch_store_locations?base_station_id=${baseStationId}&part_number=${partNumber}`,
    method: "GET",
  });
}

// for secondary parts
function getAllSecondaryParts(params) {
  return requestrApi({
    url: `/primary_parts/fetch_secondary_parts`,
    method: "GET",
    params,
  });
}

function getAlternateParts(params) {
  return requestrApi({
    url: `/primary_parts/list_alternate_parts`,
    // ?id=${id}&page=1&count_per_page=10`,
    method: "GET",
    params: params,
  });
}

function updateSecondaryPart(formData) {
  return requestrApi({
    url: "/primary_parts/update_secondary_part",
    method: "PUT",
    data: formData,
  });
}

function updateAlternateParts(formData) {
  return requestrApi({
    url: "/primary_parts/map_alternate_part",
    method: "PUT",
    data: formData,
  });
}

function deleteAlternatePart(formData) {
  return requestrApi({
    url: `/primary_parts/remove_alternate_part`,
    method: "PUT",
    data: formData,
  });
}

function fetchMaterialCertificates(id) {
  return requestrApi({
    url: `/primary_parts/material_certificates?secondary_part_id=${id}`,
    method: "GET",
  });
}

// fetching quarantine parts
function fetchQuarantineParts(id) {
  return requestrApi({
    url: `/part_conditions/listing_parts?condition=quarantine&page=1&count_per_page=10`,
    method: "GET",
  });
}

function fetchAllQuarantineParts(params) {
  return requestrApi({
    url: `/part_conditions/listing_parts`,
    method: "GET",
    params,
  });
}

function fetchShelfLifeParts(params) {
  return requestrApi({
    url: `/part_conditions/shelf_life_listing`,
    method: "GET",
    params,
  });
}

function transferPart(formData) {
  return requestrApi({
    url: `/part_requests/initiate_part_transfer`,
    method: "POST",
    data: formData,
  });
}

function getDDPartList(params) {
  return requestrApi({
    url: "/dd_parts/listing_requests",
    method: "GET",
    params: params,
  });
}

const PartsClientervices = {
  getDDPartList,
  getAllParts,
  createParts,
  deleteParts,
  fetchDependencies,
  changeStatus,
  updateParts,
  listOwnersODParts,
  bulkUpload,
  fetchSampleDocument,
  fetchRelatedParts,
  postDocument,
  getAllSecondaryParts,
  getAlternateParts,
  updateSecondaryPart,
  updateAlternateParts,
  searchPrimaryPart,
  fetchMaterialCertificates,
  fetchQuarantineParts,
  fetchShelfLifeParts,
  searchAllListedPrimaryParts,
  getAllPartsDependency,
  fetchAllQuarantineParts,
  fetchStoreLocations,
  getPrimaryPartInfo,
  deleteFile,
  deleteAlternatePart,
  transferPart,
  ddPartsFetchDependencies,
  listingNonKits,
  updateSecPartsCertification
};

export default PartsClientervices;
