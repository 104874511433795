import React, { Suspense, lazy, useReducer, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { requireAuth } from "../Auth/hoc/require_auth";
import SignIn from "../Auth/signin";
import ForgotPassword from "../Auth/forgot_password_request";
import Footer from "../../components/universal/footer";
import ResetPassword from "../Auth/reset_password";
import ToastMessage from "../../components/universal/status_messages";
import PageNotFound from "../../components/universal/page_not_found";
import LoadingIndicator from "../../components/universal/loading_indicator";
import SetPassword from '../Auth/set_password';
import SetPin from '../Auth/set_pin';
import authReducer, { INITIAL_STATE } from "../../reducers/auth_reducer";
import AuthActions from '../../actions/auth_actions';
import Home from '../Home';
import Dashboard from '../Dashboard';
import Reports from '../Reports';
import TwoFactorAuth from 'views/Auth/two_factor_auth';
const Info = lazy(() => import('../Info'));
const ComplianceSection = lazy(() => import('../Compliance'));
const ProcurementSection = lazy(() => import('../Procurement'));
const AdminSection = lazy(() => import('../Admin'));
const StoreSection = lazy(() => import('../Stores'));
const OperationsSection = lazy(() => import('../Operations'));
const PlanningSection = lazy(() => import('../Planning'));
const Accounts = lazy(() => import('../Accounts'));
const Messaging = lazy(() => import('../Messaging'));


export default function BrowserAppRoutes() {
    const ADMIN = requireAuth(['admin', 'super_admin', 'test123']);

    const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);

    useEffect(() => {
        AuthActions.setUserData(dispatch, sessionStorage.getItem("authState"))
    }, [sessionStorage.getItem("authState")])

    return (
        <Router 
            getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
          >
            <div>
                <Switch>

                    <Route exact path={'/'} component={SignIn} />
                    <Route exact path={'/auth/forgot-password'} component={ForgotPassword} />
                    <Route exact path={'/auth/set-pin'} component={SetPin} />
                    <Route exact path={'/auth/set-password'} component={SetPassword} />
                    <Route exact path={'/auth/reset-password'} component={ResetPassword} />
                    <Route exact path={'/auth/signin'} component={SignIn} />
                    <Route path={'/auth/two-factor-auth'} component={TwoFactorAuth} />
                    <Route path={'/auth/'} component={SignIn} />

                    <Suspense fallback={<LoadingIndicator Component={<img width={"200px"} src={"/loading_icon.gif"} alt={"loading..."} />} variant="page" />}>
                        <Route path={'/org/admin'} component={ADMIN(AdminSection)} />
                        <Route path={'/org/compliance'} component={ADMIN(ComplianceSection)} />
                        <Route path={'/org/procurement'} component={ADMIN(ProcurementSection)} />
                        <Route path={'/org/stores'} component={ADMIN(StoreSection)} />
                        <Route path={'/org/info'} component={ADMIN(Info)} />
                        <Route path={'/org/operations'} component={ADMIN(OperationsSection)} />
                        <Route path={'/org/planning'} component={ADMIN(PlanningSection)} />
                        <Route path={'/org/accounts'} component={ADMIN(Accounts)} />
                        <Route path={'/org/messaging'} component={ADMIN(Messaging)} />
                        <Route path={'/org/dashboard'} component={ADMIN(Dashboard)} />
                        <Route path={'/org/reports'} component={ADMIN(Reports)} />
                        <Route path={'/home'} component={ADMIN(Home)} />
                    </Suspense>
                    <Route component={PageNotFound} />
                </Switch>
                <ToastMessage />
            </div>
            <Footer />
        </Router>
    )
}
