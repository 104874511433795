import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { getTimeDiffFormatted } from "views/Operations/LineMaintenance/WorkOrder/section_detail";

export const flattenObjectToArray = (object, key) => {
  let values = [];
  for (let i = 0;i < object.length;i++) {
    if (object[i].hasOwnProperty(key)) {
      values.push(object[i][key]);
    }
  }
  return values;
};

export const userName = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user.first_name + " " + user.last_name
}
export const getUserStamp = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user.stamp_number
}

export const userCurrency = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user.currencies
}
export const getOrganisationId = () => {
  const org = JSON.parse(sessionStorage.getItem("organisation"));
  return org.id
}

export const getParams = (url) => {
  let params = {};
  let parser = document.createElement("a");
  parser.href = url;
  let query = parser.search.substring(1);
  let lets = query.split("&");
  for (let i = 0;i < lets.length;i++) {
    let pair = lets[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateCommaSeparatedEmail = (email) => {
  var re = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}(\s*,?\s*)*)+$/;
  return re.test(String(email).toLowerCase());
};

export const generatePassword = (length) => {
  var charset =
    "abcdefghjklmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789!@#$%*()";
  var retVal = "";
  for (var i = 0, n = charset.length;i < length;++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const validateLink = (link) => {
  let re =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  return re.test(link.trim());
};

export const checkFromat = (date) =>
  /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(date);
const checkDateTimeFromat = (date) =>
  /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/.test(
    date
  );
const timeStampFormat = (date) =>
  /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/.test(
    date
  );

export const formatDate = (date) => {
  let formattedDate = moment(
    date,
    checkFromat(date) ? "YYYY-MM-DD" : "DD/MM/YYYY"
  ).format("DD/MM/YYYY");
  return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const ConvertYMD = (date) => {
  let formattedDate = moment(
    date,
    checkFromat(date) ? "DD/MM/YYYY HH:mm:ss" : "YYYY-MM-DD"
  ).format("YYYY-MM-DD");
  return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const formatDateTimeFull = (date) => {
  let formattedDate = moment(date, "DD-MM-YYYY hh:mm:ss a").format(
    "DD/MM/YYYY hh:mm:ss A"
  );

  return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const formatDateTime = (date) => {
  let formattedDate = moment(
    date,
    checkDateTimeFromat(date) ? "YYYY-MM-DD HH:mm:ss A" : "DD-MM-YYYY hh:mm:ss"
  ).format("DD/MM/YYYY hh:mm A");

  return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const format24HrDateTime = (date) => {
  let formattedDate = moment(
    date,
    checkDateTimeFromat(date) || timeStampFormat(date)
      ? "YYYY-MM-DD HH:mm:ss A"
      : "DD-MM-YYYY hh:mm:ss"
  ).format("DD/MM/YYYY HH:mm");

  return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const format24HrDateOnly = (date) => {
  let formattedDate = moment(
    date,
    checkDateTimeFromat(date) || timeStampFormat(date)
      ? "YYYY-MM-DD HH:mm:ss A"
      : "DD-MM-YYYY hh:mm:ss"
  ).format("DD/MM/YYYY");

  return formattedDate === "Invalid date" ? "" : formattedDate;
};


export const _formatDate = (date) => {
  return moment(date, "YYYY-MM-DD hh:mm:ss a").format("DD/MM/YYYY HH:mm");
};
export const _formatDateUTC = (date) => {
  return moment(date, "YYYY-MM-DD hh:mm:ss a").format("YYY-MM-DD HH:mm:ss");
};

export const formatToDateOnly = (date) => {
  let formattedDate = moment(date, "DD/MM/YYYY hh:mm:ss").format("DD/MM/YYYY")
  return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const formatDateFunction = (date) => {
  return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm");
};
export const formatDateFunctiontwel = (date) => {
  let formattedDate = moment(date, "DD/MM/YYYY hh:mm").format("DD/MM/YYYY HH:mm")
  return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const formatMMMDate = (date) => {
  let formattedDate = moment(date, "DD/MMM/YYYY hh:mm").format("DD/MM/YYYY HH:mm")
  return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const newformatMMDate = (date) => {
  let formattedDate = moment(date, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm")
  return formattedDate === "Invalid date" ? "" : formattedDate;
};

export const formatDateFunctionWithAmPm = (date) => {
  return moment(date, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY HH:mm");
};
export const formatUTC = (date) => {
  return moment(date, "DD/MM/YYYY ").format("YYYY-MM-DD HH:mm:ss");
};
export const formatToMins = (date) => {
  let formattedDate = moment(date, "YYYY-MM-DD hh:mm:ss ").format(" HH:mm");
  return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const formatHoursToMins = (date) => {
  const duration = moment.duration(date)
  if (duration.isValid()) {
    const inbdDelayTime = getTimeDiffFormatted(duration)
    return inbdDelayTime
  } else {
    return "-"
  }
  // let formattedDate = moment(date, "HH:mm:ss ").format(" HH:mm");
  // return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const handleDuration = (time) => {
  let date = moment.duration(time ? time : "00:00:00").asSeconds()
  let color = date <= 0 ? "#4FC605" : "#FF0202"
  // console.log("date===", date)
  return color

  // let formattedDate = moment(date, "HH:mm:ss ").format(" HH:mm");
  // return formattedDate === "Invalid date" ? "" : formattedDate;
};



export const formatDateWithHrMin = (date) => {
  let formattedDate = moment(date, "YYYY-MM-DD hh:mm:ss ").format("DD/MM/YYYY HH:mm");
  return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const newformatDateWithHrMin = (date) => {
  let formattedDate = moment(date, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD hh:mm:ss");
  return formattedDate === "Invalid date" ? "" : formattedDate;
};
export const Nbsp = () => {
  return '\u00A0'
}
export const formatUTCToLocal = (date) => {
  let givenDate = moment.utc(date, 'DD/MM/YYYY HH:mm');
  let convertedDate = moment(givenDate).local().format('DD/MM/YYYY HH:mm')
  return convertedDate === "Invalid date" ? '' : convertedDate
}


export { makeStyles };
