import { Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'


function Chart(props) {
    const { value1, value2, value3, labels, total } = props
    const [values, setValues] = useState([])
    const [label, setlabel] = useState([])
    const [orgColor, setOrgColor] = useState([])

    useMemo(() => {
        if (value1 !== undefined) {
            values.push(value1)
        }
        if (value2 !== undefined) {
            values.push(value2)
        }
        if (value3 !== undefined) {
            values.push(value3)
        }
        setValues(values)

    }, [value1, value2, value3])

    // console.log("props=========", values)
    useMemo(() => {
        if (labels !== undefined || labels !== []) {
            // console.log("label=======", labels)
            for (let i = 0;i < labels.length;i++) {
                label.push(labels[i])
            }
        }
        setlabel(label)
    }, [labels])
    const [state, setState] = useState({
        series: values,
        options: {
            chart: {
                size: '100px',
                type: 'donut',
            },
            labels: label,
            plotOptions: {
                pie: {
                    donut: {
                        // size: '100px',
                        labels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                label: 'Calendar Actions',

                            },

                            total: {
                                show: true,
                                showAlways: true,
                                label: 'Calendar Actions',
                                fontSize: '12px',
                                formatter: function (val) {
                                    return total
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {

                formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex]
                    return [name, opts.w.config.series[opts.seriesIndex]]
                },
            },

            colors: ['#4FC605', '#FFB300', '#FF0202'],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
                position: 'bottom'
            },

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false,
                        position: 'bottom'
                    }
                }
            }]
        }
    })

    const [totalState, setTotalState] = useState({
        series: [1, 0, 0],
        options: {
            chart: {
                size: '100px',
                type: 'donut',
            },
            labels: ['0', '0', '0'],
            plotOptions: {
                pie: {
                    donut: {
                        // size: '100px',
                        labels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                label: 'Calendar Actions',

                            },

                            total: {
                                show: true,
                                showAlways: true,
                                label: 'Calendar Actions',
                                fontSize: '12px',
                                formatter: function (val) {
                                    return total
                                }
                            }
                        }
                    }
                }
            },
            colors: ['#dddddd', '#dddddd', '#dddddd'],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
                position: 'bottom'
            },

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false,
                        position: 'bottom'
                    }
                }
            }]
        }
    })



    return (
        <>
            <ReactApexChart options={value1 == 0 && value2 === 0 && value3 == 0 ? totalState.options : state.options} series={value1 == 0 && value2 === 0 && value3 == 0 ? totalState.series : state.series} type="donut" height={200} />
        </>
    )
}
export default React.memo(Chart)