import React, { useState, useReducer, useEffect, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { IconButton, SvgIcon } from '@material-ui/core'
import { ReactComponent as AccountsIcon } from "../../assets/icons/Accounts.svg";
import { ReactComponent as AdminIcon } from "../../assets/icons/Admin.svg";
import { ReactComponent as ComplianceIcon } from "../../assets/icons/Compliance.svg";
import { ReactComponent as PlanningIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as Procurement } from "../../assets/icons/Procurement.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/Dashboard.svg";
// import { ReactComponent as ReportsIcon } from "../../assets/icons/Rreporticon.svg";

import { ReactComponent as InfoIcon } from "../../assets/icons/Info.svg";
import { ReactComponent as MessagingIcon } from "../../assets/icons/Messaging.svg";
import { ReactComponent as OperationsIcon } from "../../assets/icons/Operations.svg";
import { ReactComponent as StoresIcon } from "../../assets/icons/Stores.svg";
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MROButton from "../../components/buttons";
import Typography from "@material-ui/core/Typography";
import authReducer, { INITIAL_STATE } from '../../reducers/auth_reducer';
import AuthActions from '../../actions/auth_actions';
import MessageCount from 'views/Messaging/MessageCount';

//If changed Change in /components/fullwidth_dialog styles
const drawerWidth = 250;
const orgLogo = sessionStorage.getItem('logo') || "/mro-pro-logo.png";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: `transparent linear-gradient(204deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%) 0% 0% no-repeat padding-box`,
    },
    content: {
        flexGrow: 1,
    },
    orgLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '40px',
        top: '25px',
        right: '40px',
        '& img': {
            maxWidth: '200px',
            maxHeight: '75px'
        }
    },
    navList: {
        opacity: '0.70',
        color: '#fff',
        '&:hover': {
            color: '#fff',
            borderLeft: `3px solid ${theme.palette.secondary.main}`,
            opacity: '0.80'
        },
        '&.Mui-selected': {
            color: '#fff',
            borderLeft: `3px solid ${theme.palette.secondary.main}`,
            opacity: '0.95'
        }
    },
    logout: {
        color: '#fff',
        position: 'absolute',
        bottom: 15,
        left: 15,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        cursor: 'pointer'
    },
    footerLogout: {
        color: '#fff',
        // position: 'relative',
        paddingBottom: 15,
        paddingLeft: 10,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        cursor: 'pointer'
    },
    badge: {
        width: '24px',
        height: '24px',
        borderRadius: '14px',
        // backgroundColor: 'red',
        fontSize: '16px',
        marginTop: '-1px',
        marginLeft: '-15px',
        color: '#fff'
    },
}));

const ReportsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26.5" viewBox="0 0 24 26.5">
                <g id="Group_5675" data-name="Group 5675" transform="translate(-41 -330)">
                    <line id="Line_7" data-name="Line 7" y2="13" transform="translate(42.5 343.5)" fill="none" stroke="#fff" strokeWidth="3" />
                    <line id="Line_8" data-name="Line 8" y2="15" transform="translate(49.5 341.5)" fill="none" stroke="#fff" strokeWidth="3" />
                    <line id="Line_9" data-name="Line 9" y2="10" transform="translate(56.5 346.5)" fill="none" stroke="#fff" strokeWidth="3" />
                    <line id="Line_10" data-name="Line 10" y1="18" transform="translate(63.5 338.5)" fill="none" stroke="#fff" strokeWidth="3" />
                    <g id="Ellipse_72" data-name="Ellipse 72" transform="translate(41 335)" fill="#fff" stroke="#fff" strokeWidth="1">
                        <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
                        <circle cx="1.5" cy="1.5" r="1" fill="none" />
                    </g>
                    <g id="Ellipse_75" data-name="Ellipse 75" transform="translate(48 333)" fill="#fff" stroke="#fff" strokeWidth="1">
                        <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
                        <circle cx="1.5" cy="1.5" r="1" fill="none" />
                    </g>
                    <g id="Ellipse_74" data-name="Ellipse 74" transform="translate(55 338)" fill="#fff" stroke="#fff" strokeWidth="1">
                        <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
                        <circle cx="1.5" cy="1.5" r="1" fill="none" />
                    </g>
                    <g id="Ellipse_76" data-name="Ellipse 76" transform="translate(62 330)" fill="#fff" stroke="#fff" strokeWidth="1">
                        <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
                        <circle cx="1.5" cy="1.5" r="1" fill="none" />
                    </g>
                    <path id="Path_19650" data-name="Path 19650" d="M-1880.5,303.5l7.118-2.049,6.991,5.089,6.989-8.145" transform="translate(1923 33)" fill="none" stroke="#fff" strokeWidth="0.5" />
                </g>
            </svg>
        </SvgIcon>
    )
}

const withDefaultLayout = (Component) => {
    function ComponentWithLayout(props) {
        const { container } = props;
        // const { messageTotalCount, setMessagetotalCount } = useContext(WorkshopContext)
        const classes = useStyles();
        const theme = useTheme();
        const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);
        const [mobileOpen, setMobileOpen] = useState(false);
        const [logoutConfirm, setLogoutConfirm] = useState(false);
        const [categories, setCategories] = useState([]);
        const [messageDetails, setMessageDetails] = useState({})
        const [messageTotalCount, setMessagetotalCount] = useState(0)
        const routes = JSON.parse(sessionStorage.getItem("RouterSetup"))
        const currentPath = props.location.pathname;
        const isCurrentSection = (listValue) => {
            return (currentPath.indexOf(listValue) !== -1);
        }

        const handleDrawerToggle = () => {
            setMobileOpen(!mobileOpen);
        };

        const handleLogout = () => {
            AuthActions.userSignOut();
        }

        //message bagde
        // let userDetails = JSON.parse(sessionStorage.getItem("user"));

        // const getTotalUnReadCount = async () => {
        //     const params = {
        //         user_id: userDetails?.id
        //     }
        //     MessageServices.getTotalUnReadCount(params).then((res) => {
        //         setMessageDetails(res.data)
        //         let total = parseInt(res.data.message_count) + parseInt(res.data.read_sign_count)
        //         setMessagetotalCount(total)
        //     }).catch(() => {

        //     })
        // }
        // // console.log("messagecount======", messageTotalCount)
        // useEffect(() => {
        //     getTotalUnReadCount()
        // }, [])
        const sections = [
            { name: "Operations", url: "/org/operations", redirect: `${routes?.Operations && routes?.Operations?.length > 0 ? routes?.Operations[0]?.redirect : `/org/operations/line_maintenance/work_order`}`, icon: <OperationsIcon />, badge: false },
            { name: "Planning", url: "/org/planning", redirect: `${routes?.Planning && routes?.Planning?.length > 0 ? routes?.Planning[0]?.redirect : "/org/planning/workshop/maintenance"}`, icon: <PlanningIcon style={{ width: '25px', height: '25px' }} />, badge: false },
            { name: "Dashboard", label: "Dashboards", url: "/org/dashboard", redirect: `${routes?.Dashboard && routes?.Dashboard?.length > 0 ? routes?.Dashboard[0]?.redirect : "/org/dashboard"}`, icon: <DashboardIcon />, badge: false },
            //change thislater
            { name: "Reports", label: "Reports", url: "/org/reports", redirect: "/org/reports", icon: <ReportsIcon style={{ width: '25px', height: '25px' }} />, badge: false },
            { name: "Stores", url: "/org/stores", redirect: `${routes?.Stores && routes?.Stores?.length > 0 ? routes?.Stores[0]?.redirect : "/org/stores/parts/stock"}`, icon: <StoresIcon />, badge: false },
            { name: "Procurement", url: "/org/procurement", redirect: `${routes?.Procurement && routes?.Procurement?.length > 0 ? routes?.Procurement[0]?.redirect : "/org/procurement/parts/request"}`, icon: <Procurement />, badge: false },
            { name: "Compliance", url: "/org/compliance", redirect: `${routes?.Compliance && routes?.Compliance?.length > 0 ? routes?.Compliance[0]?.redirect : '/org/compliance/user_compliance'}`, icon: <ComplianceIcon />, badge: false },
            { name: "Messaging", url: "/org/messaging", redirect: "/org/messaging", icon: <MessagingIcon />, badge: true },
            { name: "Accounts", url: "/org/accounts", redirect: `${authState?.authState?.user_role === "reading_group" ? "/org/accounts/workshop/maintenance" : routes?.Accounts && routes?.Accounts?.length > 0 ? routes?.Accounts[0]?.redirect : "/org/accounts/line_maintenance/activity_report"}`, icon: <AccountsIcon />, badge: false },
            { name: "Admin", url: "/org/admin", redirect: `${routes?.Admin && routes?.Admin?.length > 0 ? routes?.Admin[0]?.redirect : "/org/admin/bases"}`, icon: <AdminIcon /> },
            { name: "Info", url: "/org/info", redirect: "/org/info", icon: <InfoIcon />, badge: false },
        ]

        const [section, setSection] = useState(sections);

        useEffect(() => {
            AuthActions.setUserData(dispatch, sessionStorage.getItem('authState'))
        }, [])

        useEffect(() => {
            setCategories(authState?.authState?.categories)
        }, [authState?.authState?.categories])

        useEffect(() => {
            if (authState?.authState?.categories) {
                setSection(section.filter(section => {
                    return authState.authState.categories?.some(category => {
                        // console.log(section.name)
                        return section.name.includes(category.name)
                    })
                }))
            }
        }, [categories?.length])

        const [permission, setPermission] = useState([]);
        useEffect(() => {
            // console.log("auth =========", authState.authState.permissions, categories)
            if (authState?.authState?.permission) {
                setPermission(categories?.map(category => {
                    return authState.authState.permissions.find(permission => permission.source_id === category.id)
                }).filter(per => (per.read || per.write)))
            }
        }, [categories?.length])

        useEffect(() => {
            if (!!permission?.length) {
                setSection(section.filter(section => {
                    return permission?.some(per => {
                        return per?.source_type === section.name
                    })
                }))
            }
        }, [permission])

        const drawer = (
            <div>
                <div className={classes.toolbar} />
                <div className={classes.toolbar} />
                <div className={classes.orgLogo}>
                    <a href="/home">
                        <img src={orgLogo} />
                    </a>
                </div>
                <Divider />
                <List className={classes.list}>
                    {
                        !!section.length &&
                        <>
                            {section?.map((sec, i) =>
                                <ListItem className={classes.navList} key={i} button selected={isCurrentSection(sec.url)} onClick={() => props.history.push(sec.redirect)}>
                                    <ListItemIcon style={{ color: "inherit" }}>{sec.icon}</ListItemIcon>
                                    <ListItemText>{sec?.label || sec.name}</ListItemText>
                                    <ListItemText>{sec?.badge ? <MessageCount /> : ''}</ListItemText>
                                </ListItem>
                            )}
                            <ListItem className={classes.navList} key={'logout'} button onClick={() => setLogoutConfirm(true)}>
                                <ListItemIcon style={{ color: "inherit" }}><LogoutIcon /></ListItemIcon>
                                <ListItemText>Logout</ListItemText>
                            </ListItem>
                        </>
                    }
                </List>
            </div>
        );

        return (
            <>
                <div className={classes.root}>
                    <CssBaseline />
                    <nav className={classes.drawer} aria-label="navigation panel">
                        <Hidden smUp implementation="css">
                            <Drawer
                                container={container}
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                {drawer}
                                <div className={classes.footerLogout}>
                                    <div>
                                        <img src={"/mro-pro-logo.png"} width={"200px"} />
                                    </div>
                                </div>
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                {drawer}
                                <div className={classes.footerLogout}>
                                    <div>
                                        <img src={"/mro-pro-logo.png"} width={"200px"} />{/*bottom */}
                                    </div>
                                </div>
                            </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content} id={"main_content"}>
                        <Component {...props} showSideBar={handleDrawerToggle} />
                    </main>
                </div>
                <Dialog
                    maxWidth="xs"
                    fullWidth
                    open={logoutConfirm}
                    onClose={() => setLogoutConfirm(false)}
                >
                    <DialogContent style={{ padding: '24px 32px' }}>
                        <Typography variant={"subtitle2"} style={{ fontSize: '16px' }}>Confirm Logout?</Typography>
                        <Typography variant={"subtitle1"}>
                            You are about to logout.
                            Any unsaved data will be cleared.
                            You'll have to login again to use the application.
                        </Typography>
                    </DialogContent>
                    <DialogActions className={"center"} style={{ padding: '16px' }}>
                        <MROButton onClick={() => setLogoutConfirm(false)} variant={"contained"}>
                            Cancel
                        </MROButton>
                        <MROButton onClick={handleLogout} color="primary" variant={"contained"}>
                            Logout
                        </MROButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
    return (ComponentWithLayout)
}


export default withDefaultLayout;
